(function () {
  'use strict';

  /**
   * @ngdoc neo.service
   * @name neo.services.factory:User
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('User', User);

  function User($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/user');
  }
}());
